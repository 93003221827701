<template>
  <div class="page" >
    <PageHeader
      :back="false"
      :icon="$t('COMPANY.ICON')"
      :title="$t('COMPANY.TITLE')"
    >
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="#CFD8DC"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="showEdit = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.EDIT') }}
      </v-tooltip>
    </PageHeader>

    <Side
      component="forms/company/compact/FormCompanyCompact"
      :value="showEdit"
      :ids="company.ids"
      @close="closeEdit"
      @update="updatePage"
    />

    <Loading :value="loading" />
    <v-row class="mt-10 justify-center">
      <v-col lg="8" sm="11" xs="12" class="pa-4">
        <v-card flat class="pa-10">
          <v-row class="justify-center align-center">
            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img
                max-width="250"
                class="rounded_img"
                :src="company.image || '/img/empty-image.png'"
              />
            </v-avatar>
            <div class="ml-5 mr-5">
              <h2>{{ company.title }}</h2>
              <p v-if="company.url"><a class="accent--text" :href="company.url">{{company.url}}</a></p>
              <p> {{ company.description }} </p>
              <p class="grey--text text--lighten-1 mt-5 font-weight-bold">
                {{ $t('USER.TITLES')}}
              </p>
              <v-row class="mt-3 pl-3">
                <template v-for="(user, i) in listOfUsers.slice(0, Math.min(listOfUsers.length, 10))">
                  <v-tooltip bottom :key="`user-${i}`" open-delay="150" transition="scroll-y-reverse-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-avatar class="mr-n2 cursor-pointer transition-fast-in-fast-out" :class="hover ? 'mt-n1' : ''" size="35" v-bind="attrs" v-on="on">
                          <v-img
                            class="rounded_img"
                            :src="user.images || '/img/empty-photo.png'"
                          />
                        </v-avatar>
                      </v-hover>
                    </template>
                    <span class="text-center">
                      <p>{{ user.lastname + ' ' + user.firstname}}</p></span>
                      <p>
                        <small>
                          Last connection: Lundi 25 Janvier 2021
                        </small>
                      </p>
                  </v-tooltip>
                </template>
                <v-menu v-if="listOfUsers.length > 10" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-n1"
                      style="width: 35px; height: 35px"
                      fab
                      small
                      elevation="0"
                      color="grey lighten-2"
                      v-bind="attrs" 
                      v-on="on"
                    >
                      <strong>
                        +{{listOfUsers.length - 10}}
                      </strong>
                    </v-btn>
                  </template>
                  <v-list v-if="listOfUsers.length > 10" dense max-height="250">
                    <v-list-item
                      v-for="(user, i) in listOfUsers.slice(10, listOfUsers.length)"
                      :key="i"
                    >
                      <v-list-item-avatar size="35">
                       <v-img
                            class="rounded_img"
                            :src="$getImgSrc(user.images, 'md', '/img/empty-photo.png')"
                          />
                      </v-list-item-avatar>
                      <v-list-content>

                      <v-list-item-title>
                        <p>{{ user.lastname + ' ' + user.firstname }}</p>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          <small>
                            Last connection: Lundi 25 Janvier 2021
                          </small>
                      </v-list-item-subtitle>
                      </v-list-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col lg="8" sm="11" xs="12" class="pa-2">
        <v-row no-gutters>
          <v-col sm="12" md="12" lg="6" class="pa-2">
            <v-card flat class="px-5 pt-5">
              <v-card-title class="font-weight-bold">
                <v-icon class="mr-3">{{$t("HEADER.OVERVIEW_ICON")}}</v-icon>
                {{ $t("HEADER.OVERVIEW") }}
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          –
                        </strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{$t("CHANNEL_SUBSCRIPTION.TITLES")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          –
                        </strong> 
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{$t("CHANNEL_SUBSCRIPTION_CONTRACT.CONTRACT_TITLES")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          –
                        </strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{$t("CHANNEL_SUBSCRIPTION.BUDGET_TITLE")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                         –
                        </strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{$t("CHANNEL_SUBSCRIPTION.METHOD_CREDITS")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" md="12" lg="6" class="pa-2">
            <v-card flat class="px-5 pt-5">
              <v-card-title class="font-weight-bold">
                <v-icon class="mr-3">{{$t("OFFICE.ICON")}}</v-icon>
                {{ $t("OFFICE.TITLES") }}
              </v-card-title>
              <v-card-text>
                <v-list>
                  <template v-for="(office, i) in company.offices">
                    <v-list-item :key="`office-${i}`">
                      <v-list-item-content>
                        <v-list-item-title v-text="office.title"></v-list-item-title>
                        <v-list-item-subtitle>{{ $t('COMMON.CREATED') }} {{ $moment(office.created_at).fromNow() }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- Add progress bar for viewing current budget -->
                      <!-- <v-list-item-icon>
                        <v-progress-circular color='primary' :value="Math.floor(Math.random() * 100)"></v-progress-circular>
                      </v-list-item-icon> -->
                    </v-list-item>
                    <v-divider
                      v-if="i < company.offices.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import Loading from "@/components/Loading"
import Side from '@/components/Side'

export default {
  name: "PageAccount",
  components: {
    PageHeader,
    Loading,
    Side,
  },
  data: () => ({
    showEdit: false,
    valid: false,
    loading: false,
    selected: 0,
    company: {
      ids: [],
      title: undefined,
      created_at: undefined,
      updated_at: undefined,
      country: undefined,
      description: undefined,
      offices: undefined,
      image: undefined,
    },
    listOfUsers: undefined,
    uploadImage: null,
    uploadImageUrl: null,
  }),
  async created () {
    try {
      this.loading = true
      this.loadUser()
    } finally {
      this.loading = false
    }
  },
  methods: {

    async loadUser () {
      const response = await this.$services.api_user.user_session.check()

      const company = await this.$services.api_user.company.search({where: {id: response.data.user.company_id}})
      this.company.ids.push(response.data.user.company_id)
      this.company.title = company.data[0].title
      this.company.image = company.data[0].images
      this.company.created_at = company.data[0].created_at
      this.company.updated_at = company.data[0].updated_at
      this.company.country = company.data[0].country.title
      this.company.description = company.data[0].description
      this.company.offices = company.data[0].offices.filter(office => office.status === 1)

      const usersOfCompany = await this.$services.api_user.user.search({where: {company_id: response.data.user.company_id, status: 1}})
      this.listOfUsers = usersOfCompany.data
    },

    goToCompany () {
      this.$router.push('admin/company');
    },

    getImageUrl () {
      this.uploadImageUrl = this.uploadImage
        ? URL.createObjectURL(this.uploadImage)
        : this.user.image
          ? this.user.image
          : "/img/empty-photo.png";
    },

    closeEdit () {
      this.showEdit = false
    },

    updatePage () {
      this.$store.dispatch('app/init')
    }
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.page {
  overflow: auto;
  overflow-x: hidden;
}

a {
  text-decoration: initial;
}

.rounded_img {
  border-radius: 50%;
}

</style>
